import React from 'react';
import propTypes from 'prop-types';

import {
  FormControlLabel,
  RadioButton,
} from '@user-interviews/ui-design-system';

import { surveyAnswerPropType, surveyQuestionPropType } from '../../prop_types';

// Disabled when adding max-props rule.
// Future work should stay under 6 props
// eslint-disable-next-line local-rules/max-props
export function BooleanAnswerInput({
  answer,
  ariaLabel,
  bordered,
  className,
  formValue,
  hasError,
  question,
  text,
  value,
  onBlur,
  onChange,
}) {
  const checked = answer.id === formValue.answerId;

  return (
    <FormControlLabel
      aria-describedby={hasError ? `form-errors-required-${question.id}` : null}
      aria-invalid={hasError ? 'true' : null}
      aria-label={ariaLabel}
      bordered={bordered}
      checked={checked}
      className={className}
      Control={RadioButton}
      id={`pick-one-${answer.id}`}
      name={`answer-${answer.id}`}
      text={text}
      value={value}
      onBlur={onBlur}
      onChange={() => onChange({
        ...formValue,
        answerId: answer.id,
        value: answer.text,
      })}
    />
  );
}

BooleanAnswerInput.propTypes = {
  answer: surveyAnswerPropType.isRequired,
  ariaLabel: propTypes.string,
  bordered: propTypes.bool,
  className: propTypes.string,
  formValue: propTypes.shape({
    answerId: propTypes.string,
  }).isRequired,
  hasError: propTypes.bool,
  question: surveyQuestionPropType.isRequired,
  text: propTypes.string,
  value: propTypes.string.isRequired,
  onBlur: propTypes.func.isRequired,
  onChange: propTypes.func.isRequired,
};

BooleanAnswerInput.defaultProps = {
  ariaLabel: undefined,
  bordered: true,
  className: undefined,
  hasError: undefined,
  text: '',
};
