import { useMemo } from 'react';
import { type ZodSchema } from 'zod';
import { useErrorLogger } from './use_error_logger';

// The goal of this hook is to provide a sane boundary between Typescript
// and Javascript code. Using this hook with props and a corresponding
// schema will attempt to parse the unknown into the schema.  In development,
// this will throw an error on failure. In production, this will swallow the
// error, while ensuring it is logged to Appsignal.
export function useZodSchema<T>(unknownProps: unknown, schema: ZodSchema<T>) {
  const logError = useErrorLogger();

  return useMemo(() => {
    if (['development', 'test'].includes(process.env.NODE_ENV ?? '')) {
      return schema.parse(unknownProps);
    }

    try {
      return schema.parse(unknownProps);
    } catch (error) {
      logError(error as Error);

      return unknownProps as T;
    }
  }, [logError, schema, unknownProps]);
}
