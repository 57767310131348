import * as routes from 'lib/routes';

import { apiSlice } from 'api/base_slice';
import { PARTICIPANT_SURVEY_RESPONSE_SETS_TAG, RESPONSE_SETS_TAG } from 'api/tag_types';
import { deserializeJSONApiResponse, headersAndCredentials } from 'api/utils';

export const responseSetListTag = { type: RESPONSE_SETS_TAG, id: 'LIST' };

export const responseSetsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getResponseSets: builder.query({
      query: params => routes.api_response_sets_path(params),
      transformResponse: deserializeJSONApiResponse,
      providesTags: result => (result || [])
        .map(item => ({ type: RESPONSE_SETS_TAG, id: item.id }))
        .concat([responseSetListTag]),
    }),
    createResponseSet: builder.mutation({
      query: ({ surveyId, ...body }) => ({
        url: routes.api_survey_response_sets_path({ survey_id: surveyId }),
        method: 'POST',
        body,
        ...headersAndCredentials(),
      }),
      transformResponse: response => {
        const data = deserializeJSONApiResponse(response);
        return { data, meta: response.meta };
      },
      invalidatesTags: [responseSetListTag],
    }),
    updateResponseSet: builder.mutation({
      query: body => ({
        url: routes.api_response_set_path(body.data.id),
        method: 'PATCH',
        body,
        ...headersAndCredentials(),
      }),
      transformResponse: response => {
        const data = deserializeJSONApiResponse(response);
        return { data, meta: response.meta };
      },
      invalidatesTags: (_result, _error, { id }) => [
        responseSetListTag,
        { type: RESPONSE_SETS_TAG, id },
      ],
    }),
    deleteResponseSet: builder.mutation({
      query: ({ responseSetId }) => ({
        url: routes.api_response_set_path(responseSetId),
        method: 'DELETE',
        ...headersAndCredentials(),
      }),
      invalidatesTags: (_result, _error, { responseSetId }) => [
        responseSetListTag,
        { type: RESPONSE_SETS_TAG, id: responseSetId },
        { type: PARTICIPANT_SURVEY_RESPONSE_SETS_TAG, id: responseSetId },
      ],
    }),
  }),
  overrideExisting: 'throw',
});

export const {
  useGetResponseSetsQuery,
  useCreateResponseSetMutation,
  useLazyGetResponseSetsQuery,
  useUpdateResponseSetMutation,
  useDeleteResponseSetMutation,
} = responseSetsApi;
