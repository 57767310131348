import { useState } from 'react';

import { transformFormValuesToSubmissionParams } from '../../utils';

const EMPTY_SECTION_PARAMS = {
  previousSectionId: null,
  previousSectionResponses: [],
};

export const useRequestedPreviewSectionParams = () => {
  const [requestedSectionParams, setRequestedSectionParams] = useState(EMPTY_SECTION_PARAMS);

  const updateRequestedSectionParams = (formValues, previousSectionId) => {
    const currentSectionResponses = transformFormValuesToSubmissionParams(formValues);

    setRequestedSectionParams({
      previousSectionId,
      previousSectionResponses: currentSectionResponses,
    });
  };

  const resetRequestedSectionParams = () => setRequestedSectionParams(EMPTY_SECTION_PARAMS);

  return {
    requestedSectionParams,
    updateRequestedSectionParams,
    resetRequestedSectionParams,
  };
};
