import React from 'react';
import propTypes from 'prop-types';

import { Flex, FormGroup, InputLegend } from '@user-interviews/ui-design-system';
import { useFormState } from 'react-hook-form';

import { useSidebarMobileBreakpoint } from 'hooks/use_sidebar_mobile_breakpoint';

import { isPickOneQuestion } from 'lib/surveys/utils';

import * as Constants from '../../constants';
import { surveyQuestionPropType } from '../../prop_types';

import { SurveyQuestionFormGroup } from '../survey_question_form_group';
import { AnswerTitleRow } from './answer_title_row';
import { QuestionInputRow } from './question_input_row';

import styles from './grid_question.module.scss';

export function GridQuestionFormGroup({
  questions,
  questionGroup,
}) {
  const { errors } = useFormState();
  const isMobile = useSidebarMobileBreakpoint();

  if (isMobile) {
    return (
      <div>
        <InputLegend
          className={styles.Title}
          text={questionGroup.text}
        />
        {questions.map(question => (
          <SurveyQuestionFormGroup
            key={question.id}
            question={question}
          />
        ))}
      </div>
    );
  }

  const isPickOneGrid = isPickOneQuestion(questions[0]);
  const inputGroupKey = isPickOneGrid ? Constants.PICK_ONE_INPUT_GROUP_KEY : Constants.PICK_MANY_INPUT_GROUP_KEY;

  const someQuestionWithError = questions.find(question => {
    const inputKey = `q${question.id}`;
    return !!errors[inputGroupKey]?.[inputKey];
  });

  const gridHasErrors = !!someQuestionWithError;

  return (
    <FormGroup
      displayErrorText={gridHasErrors}
      elementType="fieldset"
      errors={errors[inputGroupKey]}
      id={`question-group-${questionGroup.id}`}
      inputKey={`q${someQuestionWithError?.id}`}
      label={questionGroup.text}
      labelClassName={styles.Title}
      required={questionGroup.isMandatory}
    >
      <Flex
        className={styles.GridQuestion}
        container
        flexDirection="column"
      >
        <AnswerTitleRow answers={questions[0].answers} />

        {questions.map(question => (
          <QuestionInputRow
            key={question.id}
            question={question}
          />
        ))}
      </Flex>
    </FormGroup>
  );
}

GridQuestionFormGroup.propTypes = {
  questionGroup: propTypes.object.isRequired,
  questions: propTypes.arrayOf(surveyQuestionPropType).isRequired,
};
