import * as propTypes from 'lib/prop_types';

export const surveyAnswerPropType = propTypes.shape({
  displayOrder: propTypes.number,
  id: propTypes.string,
  qualifyLogic: propTypes.string,
  qualifyLogicDisplay: propTypes.string,
  responseClass: propTypes.string,
  text: propTypes.string,
  uuid: propTypes.string,
});

export const surveyQuestionPropType = propTypes.shape({
  answers: propTypes.arrayOf(surveyAnswerPropType),
  displayOrder: propTypes.number,
  id: propTypes.string,
  isKey: propTypes.bool,
  isMandatory: propTypes.bool,
  pick: propTypes.string,
  text: propTypes.string,
  uuid: propTypes.string,
});

export const surveySkipLogicConditionPropType = propTypes.shape({
  answerId: propTypes.string,
  id: propTypes.string,
  operator: propTypes.string,
  questionId: propTypes.string,
  uuid: propTypes.string,
});

export const surveySkipLogicPropType = propTypes.shape({
  id: propTypes.string,
  joinMethod: propTypes.string,
  skipLogicConditions: propTypes.arrayOf(surveySkipLogicConditionPropType),
  targetSectionId: propTypes.string,
  uuid: propTypes.string,
});

export const surveySectionPropType = propTypes.shape({
  displayOrder: propTypes.number,
  id: propTypes.string,
  questions: propTypes.arrayOf(surveyQuestionPropType),
  skipLogics: propTypes.arrayOf(surveySkipLogicPropType),
  title: propTypes.string,
  uuid: propTypes.string,
});

export const participantScreenerKeyPropType = propTypes.shape({
  key: propTypes.string.isRequired,
  participant_id: propTypes.number.isRequired,
});
