import { useMemo, useState } from 'react';

import Http, { FormatResponse } from 'lib/http';
import { useTracking } from 'react-tracking';
import { reactTrackingEvent } from 'lib/analytics';

const defaultOnError = (error) => { throw error; };

const defaultOnSuccess = FormatResponse.jsonApi({});

function buildMakeRequest(method, setLoading, trackEvent) {
  return async (
    url,
    {
      data = {},
      onSuccess = defaultOnSuccess,
      onError = defaultOnError,
    } = {},
  ) => {
    let start;
    try {
      setLoading(true);

      start = performance.now();
      const response = await Http[method].call(null, url, data);
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    } finally {
      setLoading(false);
      const end = performance.now() - start;
      const pageLocation =
      window.location.pathname.replace(/\/[0-9]+\//g, '/:id/').replace(/\/[0-9]+/g, '/:id');
      // e.g.: Loading Page -- '/projects/:id/messages/:id'
      trackEvent({
        event: `Loading Page -- '${pageLocation.replace(/\/+$/, '')}'`,
        request_url: url,
        timeSpent: end,
      });
    }
  };
}

const useHttp = () => {
  const [loading, setLoading] = useState(false);
  const { trackEvent } = useTracking({}, { dispatch: reactTrackingEvent });
  /* eslint-disable react-hooks/exhaustive-deps */
  return {
    loading,
    delete: useMemo(() => buildMakeRequest('delete', setLoading, trackEvent), []),
    get: useMemo(() => buildMakeRequest('get', setLoading, trackEvent), []),
    patch: useMemo(() => buildMakeRequest('patch', setLoading, trackEvent), []),
    post: useMemo(() => buildMakeRequest('post', setLoading, trackEvent), []),
    put: useMemo(() => buildMakeRequest('put', setLoading, trackEvent), []),
  };
  /* eslint-enable react-hooks/exhaustive-deps */
};

export default useHttp;
