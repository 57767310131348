import React from 'react';

import {
  Button,
  Card,
  CheckboxButton, FormControlLabel, FormGroup, Input,
} from '@user-interviews/ui-design-system';
import { Controller, useForm } from 'react-hook-form';

import { PhoneNumber } from 'components/phone_number';
import { CloudflareTurnstile } from 'components/cloudflare_turnstile';
import { TimezoneSelect } from 'common/timezone_select';

import { useEnvVars } from 'hooks/use_env_vars';

import { Copy } from 'lib/generated_constants/recruiting';

import surveyFormCardStyles from 'researcher/surveys/components/survey_section_form_card.module.css';
import styles from './opt_in.module.css';

export type OptInFormFieldValues = {
  acceptDataConsentNotice: boolean,
  acceptTexts: boolean,
  cloudflareTurnstile: string,
  email: string,
  phone: { phoneCountryCode: string, phoneNumber: string}
  firstName: string,
  lastName: string,
  timezone: string,
}

interface Field {
  data?: { required: boolean };
  ui?: { hidden: boolean };
}

type FieldName = 'firstName' | 'lastName' | 'phoneNumber' | 'timezoneName';

interface DefaultQuestionsFormProps {
  isSubmitting: boolean;
  optInForm: {
    team: { dataConsentNotice: { active: boolean; body: string } };
    schema: {
      fields: Record<FieldName, Field>;
    } | null;
  };
  onSubmitForm: (formValues: OptInFormFieldValues) => void;
}

export function DefaultQuestionsForm({
  isSubmitting,
  optInForm,
  onSubmitForm,
}: DefaultQuestionsFormProps) {
  const { cloudflareTurnstileCaptchaEnabled } = useEnvVars();

  const {
    register, control, handleSubmit, formState: { errors },
  } = useForm<OptInFormFieldValues>({
    mode: 'onChange',
    shouldUnregister: false,
  });

  const isFieldRequired = (fieldName: FieldName) => optInForm.schema?.fields[fieldName]?.data?.required
  const isFieldVisible = (fieldName: FieldName) => !optInForm.schema?.fields[fieldName]?.ui?.hidden

  const validatePhone = (value: { phoneCountryCode: string, phoneNumber: string }) => {
    if (!isFieldRequired('phoneNumber')) {
      return true;
    }

    const { phoneCountryCode, phoneNumber } = value;

    if (!phoneCountryCode?.length) {
      return !phoneNumber.length ?
        'Country code and phone number cannot be left blank.' :
        'Country code cannot be left blank.';
    }

    if (!phoneNumber.length) {
      return 'Phone number cannot be left blank.';
    }

    return true;
  }

  return (
    <>
      <Card className={surveyFormCardStyles.surveyFormCard} size="sm">
        <FormGroup
          errors={errors}
          inputKey="email"
          label="Email"
          labelHtmlFor="email"
          required
        >
          <Input
            id="email"
            {...register('email', { required: true })}
          />
        </FormGroup>

        {isFieldVisible('firstName') && (
        <FormGroup
          errors={errors}
          inputKey="firstName"
          label="First name"
          labelHtmlFor="firstName"
          required={isFieldRequired('firstName')}
        >
          <Input
            id="firstName"
            {...register('firstName', { required: isFieldRequired('firstName') })}
          />
        </FormGroup>
        )}

        {isFieldVisible('lastName') && (
        <FormGroup
          errors={errors}
          inputKey="lastName"
          label="Last name"
          labelHtmlFor="lastName"
          required={isFieldRequired('lastName')}
        >
          <Input
            id="lastName"
            {...register('lastName', { required: isFieldRequired('lastName') })}
          />
        </FormGroup>
        )}

        {isFieldVisible('phoneNumber') && (
          <>
            <Controller
              control={control}
              defaultValue={{ phoneCountryCode: '', phoneNumber: '' }}
              name="phone"
              render={({ field: { value, onChange } }) => (
                <FormGroup
                  errors={errors}
                  inputKey="phone"
                >
                  <PhoneNumber
                    countryCode={{ defaultValue: '', name: 'phoneCountryCode' }}
                    phoneNumber={{ defaultValue: '', name: 'phoneNumber' }}
                    required={isFieldRequired('phoneNumber')}
                    onCountryCodeChange={({ value: phoneCountryCode }) => onChange({ ...value, phoneCountryCode })}
                    onPhoneNumberChange={({ value: phoneNumber }) => onChange({ ...value, phoneNumber })}
                  />
                </FormGroup>
              )}
              rules={{ validate: validatePhone }}
            />

            <FormControlLabel
              {...register('acceptTexts')}
              Control={CheckboxButton}
              id="accept-texts-checkbox"
              text={Copy.TEXT_MESSAGE_RELEASE_OPTIONAL}
            />
          </>
        )}

        {isFieldVisible('timezoneName') && (
        <Controller
          control={control}
          name="timezone"
          render={({ field: { value, onChange } }) => (
            <FormGroup
              errors={errors}
              inputKey="timezone"
              label="Timezone"
              labelHtmlFor="timezone-select"
              required={isFieldRequired('timezoneName')}
            >
              <TimezoneSelect
                className={undefined}
                defaultValue={undefined}
                id="timezone-select"
                menuPlacement={undefined}
                modal={undefined}
                name="timezone select!"
                useSystemFallback={undefined}
                value={value}
                onSelectChange={(option: { value: string }) => onChange(option.value)}
              />
            </FormGroup>
          )}
          rules={{ required: isFieldRequired('timezoneName') }}
        />
        )}

        {optInForm.team.dataConsentNotice?.active && (
          <FormGroup>
            <FormControlLabel
              {...register('acceptDataConsentNotice')}
              className={styles.consentNotice}
              Control={CheckboxButton}
              id="accept-data-consent-notice-checkbox"
              text={(
                <div
                  className={styles.consentNoticeBody}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: optInForm.team.dataConsentNotice.body }}
                />
              )}
            />
          </FormGroup>
        )}

        {cloudflareTurnstileCaptchaEnabled && (
          <Controller
            control={control}
            name="cloudflareTurnstile"
            render={({ field: { onChange } }) => (
              <FormGroup
                errors={errors}
                inputKey="cloudflareTurnstile"
              >
                <CloudflareTurnstile onSuccess={onChange} />
              </FormGroup>
            )}
            rules={{ required: 'Verification cannot be left blank.' }}
          />
        )}
      </Card>

      <div>
        <Button
          className={styles.submitButton}
          isLoading={isSubmitting}
          loadingText="Submitting"
          onClick={handleSubmit(onSubmitForm)}
        >
          Continue
        </Button>
      </div>
    </>
  );
}
