import { useGetOptInFormQuery } from 'api/opt_in_forms';

export function useGetOptInForm(optInFormId: string) {
  return useGetOptInFormQuery({
    optInFormId,
    include: [
      'team',
    ].join(','),
  });
}
