import React from 'react';

import { FormGroup } from '@user-interviews/ui-design-system';
import { useFormState } from 'react-hook-form';

import * as Constants from '../constants';

import { surveyQuestionPropType } from '../prop_types';
import { SurveyAnswerInput } from './answer_input_components/survey_answer_input';

import styles from './survey_question_form_group.module.scss';

export function SurveyQuestionFormGroup({
  question,
}) {
  const { errors } = useFormState();

  const questionType = question.pick;
  const answerType = question.answers[0].responseClass;

  const inputGroupKey = `${questionType}${answerType}`;
  const inputKey = `q${question.id}`;
  const hasError = !!errors[inputGroupKey]?.[inputKey];

  const elementType = questionType === Constants.QUESTION_PICK_NONE ? 'div' : 'fieldset';

  return (
    <FormGroup
      displayErrorText={hasError}
      elementType={elementType}
      errors={errors[inputGroupKey]}
      helperText={question.helpText}
      id={`question-${question.id}`}
      inputKey={inputKey}
      label={question.text}
      labelClassName={styles.Title}
      labelHtmlFor={`question-input-${question.id}`}
      required={question.isMandatory}
    >
      <SurveyAnswerInput
        hasError={hasError}
        question={question}
      />
    </FormGroup>
  );
}

SurveyQuestionFormGroup.propTypes = {
  question: surveyQuestionPropType.isRequired,
};
