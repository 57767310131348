import React from 'react';
import * as propTypes from 'lib/prop_types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock, faFileSignature, faGift, faBell,
} from 'lib/font_awesome/regular';

import {
  Accordion, AccordionItem, AccordionToggle, AccordionCollapse,
} from '@user-interviews/ui-design-system';

import './study_details_accordion.scss';

// Disabled when adding max-props rule.
// Future work should stay under 6 props
// eslint-disable-next-line local-rules/max-props
function StudyDetailsAccordion({
  description,
  requiresDocumentSignature,
  incentive,
  noIncentive,
  sessionLength,
  title,
  deadline,
}) {
  return (
    <Accordion defaultActiveKey="0">
      <AccordionItem borderless>
        <AccordionToggle
          eventKey="0"
          title={title}
        />
        <AccordionCollapse eventKey="0" UNSAFE_className="StudyDetailsAccordion__collapse">
          <div className="StudyDetailsAccordion__details">
            {deadline && (
              <div aria-label="Session length" className="StudyDetailsAccordion__details__line-item">
                <FontAwesomeIcon className="fa-fw" icon={faBell} /> Complete by {deadline}
              </div>
            )}
            <div aria-label="Session length" className="StudyDetailsAccordion__details__line-item">
              <FontAwesomeIcon className="fa-fw" icon={faClock} /> {sessionLength}
            </div>
            <div aria-label="Compensation" className="StudyDetailsAccordion__details__line-item">
              {!noIncentive && (
              <>
                <FontAwesomeIcon className="fa-fw" icon={faGift} /> {incentive}
              </>
              )}
            </div>
            {requiresDocumentSignature && (
            <div aria-label="Document signing">
              <FontAwesomeIcon className="fa-fw" icon={faFileSignature} /> Document review and signature required
            </div>
            )}
          </div>
          <p className="StudyDetailsAccordion__description">{description}</p>

        </AccordionCollapse>
      </AccordionItem>
    </Accordion>
  )
}

StudyDetailsAccordion.propTypes = {
  deadline: propTypes.string,
  description: propTypes.string.isRequired,
  incentive: propTypes.string.isRequired,
  noIncentive: propTypes.bool,
  requiresDocumentSignature: propTypes.bool.isRequired,
  sessionLength: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
};

StudyDetailsAccordion.defaultProps = {
  deadline: undefined,
  noIncentive: false,
};

export default StudyDetailsAccordion;
