import React from 'react';
import { LoadingOverlay } from '@user-interviews/ui-design-system';
import { z } from 'zod';
import { useZodSchema } from 'hooks/use_zod_schema';
import { useGetOptInForm } from 'participant/opt_in/hooks/use_get_opt_in_form';
import { SurveyPreviewPage } from 'researcher/surveys/survey_preview/survey_preview/survey_preview_page';

const optInPreviewPageSchema = z.object({
  optInFormId: z.string(),
});

export function OptInPreviewPage(props: unknown) {
  const { optInFormId } = useZodSchema(props, optInPreviewPageSchema);
  const {
    data: optInForm,
  } = useGetOptInForm(optInFormId);

  return (
    !optInForm ? (
      <LoadingOverlay />
    ) : (
      <SurveyPreviewPage
        logoUrl={optInForm.logoUrl}
        optInForm={optInForm}
        publicSurvey={false}
        surveyId={optInForm.surveyId}
        teamName={optInForm.team.name}
        title={optInForm.title}
      />
    )
  );
}
