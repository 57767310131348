import React, { useMemo } from 'react';
import propTypes from 'prop-types';

import { RadioButtonGroup } from '@user-interviews/ui-design-system';
import { Controller, useFormContext } from 'react-hook-form';

import * as Constants from '../../constants';

import { surveyQuestionPropType } from '../../prop_types';

import { PickOneAnswerInput } from './pick_one_answer_input';
import { isOtherAnswer } from '../../utils';

import styles from './answer_input.module.scss';

export function PickOneAnswerInputGroup({
  hasError,
  question,
}) {
  const { control } = useFormContext();

  const otherAnswerId = useMemo(
    () => question.answers.find(isOtherAnswer)?.id,
    [question.answers],
  );

  const validate = value => {
    if (question.isMandatory && value.answerId === null) {
      return Constants.REQUIRED_ERROR_MESSAGE;
    }
    if (value.answerId === value.otherAnswerId && value.responseOther.trim() === '') {
      return Constants.REQUIRED_OTHER_VALUE_ERROR_MESSAGE;
    }
    return null;
  };

  return (
    <Controller
      control={control}
      defaultValue={{ answerId: null, otherAnswerId, responseOther: '' }}
      name={`${Constants.PICK_ONE_INPUT_GROUP_KEY}.q${question.id}`}
      render={({ field: { onBlur, onChange, value } }) => (
        <RadioButtonGroup fullWidth>
          {question.answers.map(answer => (
            <PickOneAnswerInput
              answer={answer}
              checked={answer.id === value.answerId}
              className={styles.PickOneAnswer}
              formValue={value}
              hasError={hasError}
              isOtherAnswer={isOtherAnswer(answer)}
              key={answer.id}
              question={question}
              text={answer.text}
              value={answer.id}
              onBlur={onBlur}
              onChange={onChange}
            />
          ))}
        </RadioButtonGroup>
      )}
      rules={{ validate }}
    />
  );
}

PickOneAnswerInputGroup.propTypes = {
  hasError: propTypes.bool,
  question: surveyQuestionPropType.isRequired,
};

PickOneAnswerInputGroup.defaultProps = {
  hasError: undefined,
};
