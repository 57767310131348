import { useGetSurveySectionsQuery } from 'api/survey_sections';

export const useGetNextSurveySection = (
  surveyId,
  {
    previousSectionId = null,
    previousSectionResponses = null,
    projectId = null,
    participantScreenerKey = null,
    skip = false,
  } = {},
) => (
  useGetSurveySectionsQuery({
    surveyId,
    params: {
      limit: 1,
      submittedPreviewSectionId: previousSectionId,
      submittedPreviewResponsesAttributes: previousSectionResponses,
      meta: {
        projectId,
        participantId: participantScreenerKey?.participant_id,
        participantScreenerKey: participantScreenerKey?.key,
      },
    },
  }, {
    skip,
  })
);
