import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isServer } from 'lib/ssr_utils';

export const MEDIA_BREAKPOINT_LG = '(min-width: 992px)';
export const MEDIA_BREAKPOINT_MD = '(min-width: 768px)';
export const MEDIA_BREAKPOINT_SM = '(min-width: 576px)';
export const MEDIA_BREAKPOINT_XS = '(max-width: 575px)';

export default (mediaQuery) => {
  const mediaQueryList = useMemo(
    () => (isServer ? null : window.matchMedia(mediaQuery)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [mediaQueryMatches, setMediaQueryMatches] = useState(mediaQueryList?.matches);

  const handleMediaQueryChange = useCallback(
    ({ matches }) => setMediaQueryMatches(matches),
    [],
  );

  useEffect(
    () => {
      function addEventListener() {
        if (!mediaQueryList) return;

        if (typeof mediaQueryList.addEventListener === 'function') {
          mediaQueryList.addEventListener('change', handleMediaQueryChange);
        } else {
          // This is deprecated, though Safari 13 still has this so keep it here until that's old
          mediaQueryList.addListener(handleMediaQueryChange);
        }
      }

      function removeEventListener() {
        if (!mediaQueryList) return;

        if (typeof mediaQueryList.removeEventListener === 'function') {
          mediaQueryList.removeEventListener('change', handleMediaQueryChange);
        } else {
          // This is deprecated, though Safari 13 still has this so keep it here until that's old
          mediaQueryList.removeListener(handleMediaQueryChange);
        }
      }

      addEventListener();

      return removeEventListener;
    },
    [handleMediaQueryChange, mediaQueryList],
  );

  return mediaQueryMatches;
};
