import { useGetSurveySectionsQuery } from 'api/survey_sections';

type UseGetFirstSurveySectionParams = {
  optInFormId: string,
  participantId: string,
  participantOptInFormKey: string,
  skip: boolean,
  surveyId: string,
}

export const useGetFirstSurveySection = ({
  optInFormId,
  participantId,
  participantOptInFormKey,
  skip = false,
  surveyId,
}: UseGetFirstSurveySectionParams) => {
  const queryResult = useGetSurveySectionsQuery({
    surveyId,
    params: {
      limit: 1,
      meta: {
        optInFormId,
        participantId,
        participantOptInFormKey,
      },
    },
  }, {
    skip,
  });

  return {
    ...queryResult,
    firstSurveySection: queryResult.data?.[0],
  };
};
