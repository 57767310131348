import { useEffect } from 'react';

export const useAlertFocus = () => {
  useEffect(() => {
    // For a11y on Safari browsers:
    // if any Toast errors are present, then use Javascript to set focus
    // to ensure the screen reader reads it first
    const alertElements = document.getElementsByClassName('Alert__message');
    if (alertElements.length) {
      alertElements[0].focus();
    }
  }, []);
};
