import React from 'react';
import propTypes from 'prop-types';

import { Input } from '@user-interviews/ui-design-system';
import { Controller, useFormContext } from 'react-hook-form';
import { useTracking } from 'react-tracking';

import { trackingEvents } from 'lib/analytics';
import * as Constants from '../../constants';

import { surveyQuestionPropType } from '../../prop_types';

export function TextAnswerInput({
  hasError,
  question,
}) {
  const { control } = useFormContext();
  const { trackEvent } = useTracking();

  const answer = question.answers[0];
  const answerType = answer.responseClass;
  const isLongTextAnswer = answerType === Constants.RESPONSE_CLASS_TEXT;

  const InputComponent = isLongTextAnswer ? 'textarea' : Input;
  const inputGroupKey = isLongTextAnswer ?
    Constants.LONG_ANSWER_INPUT_GROUP_KEY : Constants.SHORT_ANSWER_INPUT_GROUP_KEY;

  const handlePaste = () => trackEvent({
    event: trackingEvents.PARTICIPANT_SURVEY_RESPONSE_ANSWER_TEXT_PASTED,
    question_id: question.id,
  });

  const validate = (inputValue) => {
    if (question.isMandatory && inputValue.value.trim() === '') {
      return Constants.REQUIRED_ERROR_MESSAGE;
    }
    return null;
  };

  return (
    <Controller
      control={control}
      defaultValue={{ answerId: answer.id, value: '' }}
      name={`${inputGroupKey}.q${question.id}`}
      render={({
        field: {
          onBlur,
          onChange,
          ref,
        },
      }) => (
        <InputComponent
          aria-describedby={hasError ? `form-errors-required-${question.id}` : null}
          aria-invalid={hasError}
          aria-required={question.isMandatory}
          className="form-control"
          id={`question-input-${question.id}`}
          ref={ref}
          onBlur={onBlur}
          onChange={(e) => onChange({ answerId: answer.id, value: e.currentTarget.value })}
          onPaste={handlePaste}
        />
      )}
      rules={{
        validate,
      }}
    />
  );
}

TextAnswerInput.propTypes = {
  hasError: propTypes.bool.isRequired,
  question: surveyQuestionPropType.isRequired,
};
