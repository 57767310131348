import { useGetFirstSurveySection } from '../../hooks/use_get_first_survey_section';
import { useGetNextSurveySection } from '../../hooks/use_get_next_survey_section';

export const useGetSurveyPreviewSection = (
  surveyId,
  {
    previousSectionId = null,
    previousSectionResponses = null,
  },
) => {
  const isFirstSection = !previousSectionId;

  const firstSection = useGetFirstSurveySection(surveyId, { skip: !isFirstSection });

  const nextSection = useGetNextSurveySection(surveyId, {
    previousSectionId,
    previousSectionResponses,
    skip: isFirstSection,
  });

  const queryResult = isFirstSection ? firstSection : nextSection;
  const previewCompleted = !isFirstSection && !nextSection.isFetching && !nextSection.data?.[0];

  // We want the latest loaded survey section
  // So when actively requesting "next" section, grab latest loaded "next" section
  // If there is no loaded "next" section, grab the first section till we finish fetching "next" one
  const currentSurveySection = (!isFirstSection && nextSection.data?.[0]) || firstSection.data?.[0];

  return {
    ...queryResult,
    isLoadingFirstSection: firstSection.isLoading,
    previewCompleted,
    surveySection: currentSurveySection,
  };
};
