import React from 'react'
import { shouldIgnoreTurnstileError } from 'lib/telemetry/ignore_errors';
import { Turnstile } from '@marsidev/react-turnstile';
import { useEnvVars } from 'hooks/use_env_vars';

type CloudflareTurnstileProps = {
  onSuccess: () => void;
}

export function CloudflareTurnstile({
  onSuccess = () => {},
}: CloudflareTurnstileProps) {
  const { cloudflareTurnstileCaptchaSiteKey } = useEnvVars();

  const handleError = (code: string) => shouldIgnoreTurnstileError(code);

  return (
    <Turnstile
      siteKey={cloudflareTurnstileCaptchaSiteKey || ''}
      onError={handleError}
      onSuccess={onSuccess}
    />
  )
}
