import * as routes from 'lib/routes';

import { apiSlice } from 'api/base_slice';
import { SURVEY_SECTIONS_TAG } from 'api/tag_types';
import { deserializeJSONApiResponse } from 'api/utils';

export const surveySectionsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getSurveySections: builder.query({
      query: ({ surveyId, params }) => ({
        url: routes.api_survey_survey_sections_path({
          survey_id: surveyId,
          ...params,
        }),
      }),
      providesTags: (result, _error, { surveyId }) => (result?.data || [])
        .map(item => ({ type: SURVEY_SECTIONS_TAG, id: item.id }))
        .concat([{ type: SURVEY_SECTIONS_TAG, id: `${surveyId}_LIST` }]),
      transformResponse: deserializeJSONApiResponse,
    }),
  }),
  overrideExisting: 'throw',
});

export const {
  useGetSurveySectionsQuery,
} = surveySectionsApi;
