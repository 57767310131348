import type { ReactElement } from 'react';

import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { isServer } from 'lib/ssr_utils';

export function DopeBrowserRouter({ location, children }: { location?: string, children: ReactElement }) {
  if (isServer) {
    return (
      <StaticRouter location={location}>
        <CompatRouter>
          {children}
        </CompatRouter>
      </StaticRouter>
    );
  }

  return (
    <BrowserRouter>
      <CompatRouter>
        {children}
      </CompatRouter>
    </BrowserRouter>
  );
}
