import React from 'react';

import * as routes from 'lib/routes';
import { trackingEvents } from 'lib/analytics/constants';
import { TeamActionTypes } from 'lib/generated_constants/team_action_types';

import { Authorization } from 'common/authorization';
import { NavigationItem } from '../navigation_item';
import styles from './footer.module.scss';

const {
  NAVIGATION_PARTICIPANTS_CLICKED,
  NAVIGATION_PROJECTS_CLICKED,
} = trackingEvents;

export function Footer() {
  return (
    <footer className={styles.footer}>
      <nav>
        <NavigationItem
          className={styles.footerNavigationItem}
          event={NAVIGATION_PROJECTS_CLICKED}
          href={routes.account_dashboard_path()}
          iconSrcBase="/images/nav_icon_projects"
          label="Projects"
        />

        <Authorization to={TeamActionTypes.SEARCH_PARTICIPANTS}>
          <NavigationItem
            className={styles.footerNavigationItem}
            event={NAVIGATION_PARTICIPANTS_CLICKED}
            href={routes.search_participants_path()}
            iconSrcBase="/images/nav_icon_participants"
            label="Participants"
          />
        </Authorization>
      </nav>
    </footer>
  );
}
