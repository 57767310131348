import React, { useEffect } from 'react';

import {
  CardStack,
} from '@user-interviews/ui-design-system';
import { SetMessageHandler } from '@user-interviews/ui-design-system/lib/Toast/useToast';
import { FormProvider, useForm } from 'react-hook-form';

import { TitledPage } from 'common/routing';

import { SurveySectionFormValues } from 'participant/project_application/components/survey/types';

import { SurveySectionForm } from 'researcher/surveys/components/survey_section_form';

import { useGetFirstSurveySection } from '../hooks/use_get_first_survey_section';
import { useSurveyResponseSet } from '../hooks/use_survey_response_set';

interface SurveyProps {
  optInFormId: string,
  participantOptInFormKey: string,
  participantId: string,
  surveyId: string,
  setToastMessage: SetMessageHandler,
  onComplete: () => void,
}

export function Survey({
  participantId,
  optInFormId,
  participantOptInFormKey,
  surveyId,
  setToastMessage,
  onComplete,
}: SurveyProps) {
  const form = useForm<SurveySectionFormValues>({ shouldUnregister: true });

  const {
    currentSurveySection: responseSetSurveySection,
    handleSubmit,
    isLoading: isLoadingResponseSet,
    isSubmitting,
    responseSet,
  } = useSurveyResponseSet({
    optInFormId,
    participantId,
    participantOptInFormKey,
    surveyId,
    setToastMessage,
  });

  const {
    isLoading: isLoadingFirstSurveySection,
    firstSurveySection,
  } = useGetFirstSurveySection({
    optInFormId,
    participantId,
    participantOptInFormKey,
    surveyId,
    skip: isLoadingResponseSet || !!responseSet,
  });

  const surveyCompleted = !!responseSet?.completedAt;
  useEffect(() => {
    if (surveyCompleted) onComplete();
  }, [surveyCompleted, onComplete]);

  const currentSurveySection = responseSetSurveySection || firstSurveySection;
  const isLoading = isLoadingResponseSet || isLoadingFirstSurveySection;

  const pageTitlePrefix = currentSurveySection?.title ? `${currentSurveySection.title} - ` : '';
  const pageTitle = `${pageTitlePrefix}Survey`;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...form}>
      <TitledPage title={pageTitle}>
        <CardStack size="sm">
          <SurveySectionForm
            cardSize="sm"
            isLoading={isLoading || surveyCompleted}
            isSubmitting={isSubmitting}
            surveySection={currentSurveySection}
            onSubmit={form.handleSubmit(handleSubmit)}
          />
        </CardStack>
      </TitledPage>
    </FormProvider>
  );
}
