import React from 'react';

import { Input, InputLabel, SingleSelect } from '@user-interviews/ui-design-system';

import * as propTypes from 'lib/prop_types';
import { CountriesByCode } from 'lib/generated_constants/countries';

import './phone_number.scss';

const SELECT_OPTIONS = Object.keys(CountriesByCode).map((code) => {
  const phoneCode = CountriesByCode[code].phone_code;
  const countryName = CountriesByCode[code].name;

  let formattedCountryName = `${countryName}`;
  if (phoneCode) formattedCountryName += ` (+${phoneCode})`;

  const label = `${code} - ${formattedCountryName}`;

  return { label, value: code };
});

// Disabled when adding max-props rule.
// Future work should stay under 6 props
// eslint-disable-next-line local-rules/max-props
export function PhoneNumber({
  className,
  countryCode,
  disabled,
  hideLabels,
  labelClassName,
  phoneNumber,
  required,
  onCountryCodeChange,
  onPhoneNumberChange,
}) {
  const countryCodeAria = {
    label: countryCode.labelText || 'Country code',
  };

  const phoneNumberAria = {
    label: phoneNumber.labelText || 'Phone number',
  };

  const isPhoneNumberOrCountryCodeErrorPresent =
    phoneNumber.phoneNumberErrorPresent || countryCode.phoneCountryCodeErrorPresent;

  const getDefaultCountryCodeInput = () => (
    SELECT_OPTIONS.find(({ value }) => value === countryCode.defaultValue)
  );

  const handleCountryCodeChange = (option) => {
    const value = option ? option.value : null;

    if (onCountryCodeChange) {
      onCountryCodeChange({ name: countryCode.name, value });
    }
  };

  const handlePhoneNumberChange = ({ target: { value } }) => {
    if (onPhoneNumberChange) {
      onPhoneNumberChange({ name: phoneNumber.name, value });
    }
  };

  return (
    <div className={`PhoneNumber ${className || ''}`}>
      <div className="PhoneNumber__country-code">
        {
          !hideLabels && (
            <InputLabel
              className={`${labelClassName || ''}`}
              labelHelperText={countryCode.optional && 'Optional'}
              labelHtmlFor="country-code"
              required={required}
              text={countryCodeAria.label}
            />
          )
        }
        <SingleSelect
          defaultValue={getDefaultCountryCodeInput()}
          disabled={disabled}
          inputId="country-code"
          isClearable
          isSearchable
          name={countryCode.name}
          options={SELECT_OPTIONS}
          placeholder={countryCode.placeholder}
          onChange={handleCountryCodeChange}
        />
      </div>

      <div className="PhoneNumber__phone-number">
        {
          !hideLabels && (
            <InputLabel
              className={`${labelClassName || ''}`}
              labelHelperText={phoneNumber.optional && 'Optional'}
              labelHtmlFor="phone-number"
              required={required}
              text={phoneNumberAria.label}
            />
          )
        }
        <Input
          aria-describedby={isPhoneNumberOrCountryCodeErrorPresent ? 'phone-error' : null}
          aria-invalid={isPhoneNumberOrCountryCodeErrorPresent}
          defaultValue={phoneNumber.defaultValue}
          disabled={disabled}
          id="phone-number"
          name={phoneNumber.name}
          placeholder={phoneNumber.placeholder}
          required={required}
          type="tel"
          onChange={handlePhoneNumberChange}
        />
      </div>
    </div>
  );
}

PhoneNumber.propTypes = {
  className: propTypes.string,
  countryCode: propTypes.shape({
    defaultValue: propTypes.string,
    labelText: propTypes.string,
    name: propTypes.string,
    optional: propTypes.bool,
    phoneCountryCodeErrorPresent: propTypes.bool,
    placeholder: propTypes.string,
  }),
  disabled: propTypes.bool,
  hideLabels: propTypes.bool,
  labelClassName: propTypes.string,
  phoneNumber: propTypes.shape({
    defaultValue: propTypes.string,
    labelText: propTypes.oneOfType([
      propTypes.string,
      propTypes.element,
    ]),
    name: propTypes.string,
    optional: propTypes.bool,
    phoneNumberErrorPresent: propTypes.bool,
    placeholder: propTypes.string,
  }),
  required: propTypes.bool,
  onCountryCodeChange: propTypes.func,
  onPhoneNumberChange: propTypes.func,
};

PhoneNumber.defaultProps = {
  className: undefined,
  countryCode: {},
  disabled: false,
  hideLabels: false,
  labelClassName: undefined,
  phoneNumber: {},
  required: false,
  onCountryCodeChange: undefined,
  onPhoneNumberChange: undefined,
};
