import React from 'react';

import { FlexContainer, Text, Heading } from '@user-interviews/ui-design-system';

import stars from 'images/stars.png';

export function OptInSuccess() {
  return (
    <FlexContainer
      alignItems="center"
      flexDirection="column"
      gap={2}
      justifyContent="center"
      width="sm"
    >
      <img alt="Stars" src={stars} />
      <Heading level={4} size="xxl">
        You're all set!
      </Heading>
      <Text textAlign="center">
        Thank you for joining our research panel! We'll be in touch when there is a study we think you'd be a good
        fit for.
      </Text>
    </FlexContainer>
  );
}
