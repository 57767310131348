import { useGetSurveySectionsQuery } from 'api/survey_sections';

export const useGetFirstSurveySection = (
  surveyId,
  {
    projectId = null,
    participantScreenerKey = null,
    skip = false,
  } = {},
) => {
  const queryResult = useGetSurveySectionsQuery({
    surveyId,
    params: {
      limit: 1,
      meta: {
        projectId,
        participantId: participantScreenerKey?.participant_id,
        participantScreenerKey: participantScreenerKey?.key,
      },
    },
  }, {
    skip,
  });

  return {
    ...queryResult,
    firstSurveySection: queryResult.data?.[0],
  };
};
