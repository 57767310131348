import React from 'react';
import propTypes from 'prop-types';

import { CardSizes } from '@user-interviews/ui-design-system';

import { HubSurveyPreviewHeader } from './hub_survey_preview_header';
import { RecruitSurveyPreviewHeader } from './recruit_survey_preview_header';

export function SurveyPreviewFormHeader({
  cardSize,
  logoUrl,
  projectDetails,
  publicSurvey,
  teamName,
  title,
}) {
  return (
    publicSurvey ? (
      <RecruitSurveyPreviewHeader title={title} />
    ) : (
      <HubSurveyPreviewHeader
        cardSize={cardSize}
        logoUrl={logoUrl}
        projectDetails={projectDetails}
        teamName={teamName}
        title={title}
      />
    )
  );
}

SurveyPreviewFormHeader.propTypes = {
  cardSize: propTypes.oneOf(Object.values(CardSizes)).isRequired,
  logoUrl: propTypes.string,
  projectDetails: propTypes.object,
  publicSurvey: propTypes.bool.isRequired,
  teamName: propTypes.string,
  title: propTypes.string.isRequired,
};

SurveyPreviewFormHeader.defaultProps = {
  logoUrl: undefined,
  projectDetails: undefined,
  teamName: undefined,
};
