import React from 'react';

import { Flex } from '@user-interviews/ui-design-system';

import { isPickOneQuestion } from 'lib/surveys/utils';

import { surveyQuestionPropType } from '../../prop_types';

import { PickOneInputRow } from './pick_one_input_row';
import { PickManyInputRow } from './pick_many_input_row';
import { QuestionTitleCell } from './row_title_cell';

export function QuestionInputRow({ question }) {
  const isPickOne = isPickOneQuestion(question);
  const InputRow = isPickOne ? PickOneInputRow : PickManyInputRow;

  return (
    <Flex
      alignItems="stretch"
      container
      key={question.id}
    >
      <QuestionTitleCell title={question.text} />
      <InputRow question={question} />
    </Flex>
  );
}

QuestionInputRow.propTypes = {
  question: surveyQuestionPropType.isRequired,
};
