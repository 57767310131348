import React from 'react';
import propTypes from 'prop-types';

import { Page } from 'components/page';
import { OwnerBoundary } from 'components/owner_boundary';

import { useAlertFocus } from '../../hooks/use_alert_focus';
import { SurveyPreview } from './survey_preview';

// Disabled when adding max-props rule.
// Future work should stay under 6 props
// eslint-disable-next-line local-rules/max-props
export function SurveyPreviewPage({
  logoUrl,
  optInForm,
  projectDetails,
  publicSurvey,
  surveyId,
  teamName,
  title,
}) {
  useAlertFocus();

  return (
    <OwnerBoundary RESEARCH_EXPERIENCE>
      <Page isPadded>
        <SurveyPreview
          logoUrl={logoUrl}
          optInForm={optInForm}
          projectDetails={projectDetails}
          publicSurvey={publicSurvey}
          surveyId={surveyId}
          teamName={teamName}
          title={title}
        />
      </Page>
    </OwnerBoundary>
  );
}

SurveyPreviewPage.propTypes = {
  logoUrl: propTypes.string,
  optInForm: propTypes.object,
  projectDetails: propTypes.object,
  publicSurvey: propTypes.bool.isRequired,
  surveyId: propTypes.number, // Because opt in form might not have a survey, this cannot be required
  teamName: propTypes.string,
  title: propTypes.string.isRequired,
};

SurveyPreviewPage.defaultProps = {
  logoUrl: undefined,
  optInForm: null,
  projectDetails: undefined,
  teamName: undefined,
  surveyId: undefined,
};
