import React from 'react';
import propTypes from 'prop-types';

import { DateTimePicker } from 'common/deprecated';

import { Controller, useFormContext } from 'react-hook-form';

import * as Constants from '../../constants';

import { surveyQuestionPropType } from '../../prop_types';

export function DateAnswerInput({
  hasError,
  question,
}) {
  const { control } = useFormContext();

  const answer = question.answers[0];

  const validate = (inputValue) => {
    if (question.isMandatory && inputValue.value.trim() === '') {
      return Constants.REQUIRED_ERROR_MESSAGE;
    }
    return null;
  };

  return (
    <Controller
      control={control}
      defaultValue={{ answerId: answer.id, value: '' }}
      name={`${Constants.DATE_INPUT_GROUP_KEY}.q${question.id}`}
      render={({ field: { value, onChange } }) => (
        <DateTimePicker
          aria-describedby={hasError ? `form-errors-required-${question.id}` : null}
          aria-invalid={hasError}
          aria-required={question.isMandatory}
          className="form-control"
          date={value.value}
          dateViewMode="years"
          id={`question-input-${question.id}`}
          showPickerEnforcedInput
          onChangeDate={event => onChange({ answerId: answer.id, value: event.startDate })}
        />

      )}
      rules={{
        validate,
      }}
    />
  );
}

DateAnswerInput.propTypes = {
  hasError: propTypes.bool.isRequired,
  question: surveyQuestionPropType.isRequired,
};
