import React from 'react';
import propTypes from 'prop-types';

import { CheckboxButton, FormControlLabel, Input } from '@user-interviews/ui-design-system';

import { surveyAnswerPropType, surveyQuestionPropType } from '../../prop_types';

// Disabled when adding max-props rule.
// Future work should stay under 6 props
// eslint-disable-next-line local-rules/max-props
export function PickManyAnswerInput({
  answer,
  ariaLabel,
  bordered,
  checked,
  className,
  formValue,
  hasError,
  isOtherAnswer,
  question,
  text,
  value,
  onBlur,
  onChange,
  onFormChange,
}) {
  return (
    <FormControlLabel
      aria-describedby={hasError ? `form-errors-required-${question.id}` : null}
      aria-invalid={hasError ? 'true' : null}
      aria-label={ariaLabel}
      bordered={bordered}
      checked={checked}
      className={className}
      Control={CheckboxButton}
      id={`pick-many-${answer.id}`}
      name={`answer-${answer.id}`}
      text={text}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    >
      {isOtherAnswer && checked && (
        <Input
          aria-label="Other input"
          autoComplete="off"
          name={`answer-${answer.id}-other-input`}
          placeholder="Type your response here"
          type="text"
          value={formValue.responseOther}
          onBlur={onBlur}
          onChange={(e) => onFormChange({ ...formValue, responseOther: e.currentTarget.value })}
        />
      )}
    </FormControlLabel>
  );
}

PickManyAnswerInput.propTypes = {
  answer: surveyAnswerPropType.isRequired,
  ariaLabel: propTypes.string,
  bordered: propTypes.bool,
  checked: propTypes.bool,
  className: propTypes.string,
  formValue: propTypes.shape({
    answerIds: propTypes.arrayOf(propTypes.string),
    otherAnswerId: propTypes.string,
    responseOther: propTypes.string,
  }).isRequired,
  hasError: propTypes.bool,
  isOtherAnswer: propTypes.bool,
  question: surveyQuestionPropType.isRequired,
  text: propTypes.string,
  value: propTypes.string.isRequired,
  onBlur: propTypes.func.isRequired,
  onChange: propTypes.func,
  onFormChange: propTypes.func.isRequired,
};

PickManyAnswerInput.defaultProps = {
  ariaLabel: undefined,
  bordered: true,
  checked: true,
  className: undefined,
  hasError: undefined,
  isOtherAnswer: false,
  text: '',
  onChange: undefined,
};
