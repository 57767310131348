/* eslint-disable camelcase */

import * as routes from 'lib/routes';
import { RequestBodyAttributes, RequestParameters, ResponseBody } from 'lib/swagger_helper';

import { apiSlice } from 'api/base_slice';
import { PARTICIPANTS_TAG } from 'api/tag_types';
import { headersAndCredentials } from 'api/utils';

export type OptInFormParticipantRawResponse = ResponseBody<'upsertParticipantPopulationOptInFormParticipant'>;

export function transformResponse(
  response: OptInFormParticipantRawResponse,
) {
  const { data, meta } = response;

  if (!data) return undefined;

  return ({
    id: data.id,
    ...data.attributes,
    optInFormKey: meta.optInFormKey,
  });
}

export type OptInFormParticipantAPIResponse = ReturnType<typeof transformResponse>;

const participantPopulationOptInFormParticipantsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    upsertParticipantPopulationOptInFormParticipant: builder.mutation<
      OptInFormParticipantAPIResponse,
      RequestBodyAttributes<'upsertParticipantPopulationOptInFormParticipant'> &
        RequestParameters<'upsertParticipantPopulationOptInFormParticipant'>
    >({
      query: (params) => ({
        url: routes.api_opt_in_form_participants_path(params.opt_in_form_id),
        method: 'PUT',
        body: params,
        ...headersAndCredentials(),
      }),
      invalidatesTags: [PARTICIPANTS_TAG],
      transformResponse,
    }),
  }),
  overrideExisting: 'throw',
});

export const {
  useUpsertParticipantPopulationOptInFormParticipantMutation,
} = participantPopulationOptInFormParticipantsApi;
