import React, { useMemo, useState } from 'react';
import propTypes from 'prop-types';

import { CardStack, Flex } from '@user-interviews/ui-design-system';

import { TitledPage } from 'common/routing';

import { ProjectDefaultName } from 'lib/generated_constants/projects'
import { Header } from 'participant/opt_in/components/header';
import { DefaultQuestionsForm } from 'participant/opt_in/components/default_questions_form';
import { surveySectionPropType } from '../../prop_types';
import { PreviewBanner } from './preview_banner';
import { SurveyPreviewFormHeader } from './survey_preview_form_header';
import { SurveyPreviewEnd } from './survey_preview_end';
import { SurveySectionForm } from '../../components/survey_section_form';
import { determineCardSize } from '../../utils';

import styles from './survey_preview_form.module.scss';

// Disabled when adding max-props rule.
// Future work should stay under 6 props
// eslint-disable-next-line local-rules/max-props
export function SurveyPreviewForm({
  isLoading,
  isSubmitting,
  logoUrl,
  previewCompleted,
  projectDetails,
  publicSurvey,
  surveySection,
  teamName,
  title,
  onRestartPreview,
  onSubmitSection,
  optInForm,
}) {
  const titleWithDefaultName = title || ProjectDefaultName;
  const pageTitlePrefix = surveySection?.title ? `${surveySection.title} - ` : '';
  const pageTitle = `${(optInForm ? 'Opt-in form' : `${pageTitlePrefix}Survey`)} preview - ${titleWithDefaultName}`;

  const cardSize = useMemo(() => determineCardSize(surveySection), [surveySection]);
  const [submittedDefaultQuestions, setSubmittedDefaultQuestions] = useState(false);

  const handleRestartPreview = () => {
    setSubmittedDefaultQuestions(false)
    onRestartPreview()
  }

  const showEndPage = previewCompleted || (optInForm && !optInForm.surveyId && submittedDefaultQuestions);

  return (
    <TitledPage title={pageTitle}>
      <Flex
        alignItems="center"
        className={styles.SurveyPreviewForm}
        container
        flexDirection="column"
      >
        <PreviewBanner />
        <CardStack size={cardSize}>
          {optInForm ? (
            <Header
              isPreview
              logoOnly={showEndPage}
              logoUrl={optInForm.logoUrl}
              optInFormDescription={optInForm.description}
              optInFormTitle={optInForm.title}
              teamName={optInForm.team.name}
            />
          ) : (
            <SurveyPreviewFormHeader
              cardSize={cardSize}
              logoUrl={logoUrl}
              projectDetails={projectDetails}
              publicSurvey={publicSurvey}
              teamName={teamName}
              title={titleWithDefaultName}
            />
          )}

          {showEndPage ? (
            <SurveyPreviewEnd
              isOptInForm={!!optInForm}
              onRestartPreview={handleRestartPreview}
            />
          ) : (
            optInForm && !submittedDefaultQuestions ? (
              <DefaultQuestionsForm
                isSubmitting={false}
                optInForm={optInForm}
                onSubmitForm={() => { setSubmittedDefaultQuestions(true) }}
              />
            ) : (
              <SurveySectionForm
                cardSize={cardSize}
                isLoading={isLoading}
                isSubmitting={isSubmitting}
                surveySection={surveySection}
                onSubmit={onSubmitSection}
              />
            )
          )}
        </CardStack>
      </Flex>
    </TitledPage>
  );
}

SurveyPreviewForm.propTypes = {
  isLoading: propTypes.bool.isRequired,
  isSubmitting: propTypes.bool.isRequired,
  logoUrl: propTypes.string,
  optInForm: propTypes.object,
  previewCompleted: propTypes.bool.isRequired,
  projectDetails: propTypes.object,
  publicSurvey: propTypes.bool.isRequired,
  surveySection: surveySectionPropType,
  teamName: propTypes.string,
  title: propTypes.string,
  onRestartPreview: propTypes.func.isRequired,
  onSubmitSection: propTypes.func.isRequired,
};

SurveyPreviewForm.defaultProps = {
  logoUrl: undefined,
  projectDetails: undefined,
  title: undefined,
  surveySection: null,
  teamName: undefined,
  optInForm: null,
};
