import React from 'react';

import styles from './preview_banner.module.scss';

export function PreviewBanner() {
  return (
    <div className={styles.PreviewBanner}>
      <span
        aria-label="Magnifying Glass"
        className={styles.Icon}
        role="img"
      >
        🔍&nbsp;
      </span>
      <span className={styles.Header}>
        You are in preview mode.&nbsp;
      </span>
      <br className={styles.MobileBreak} />
      <span>
        Any added skip logic will be applied. We recommend testing all possible paths.
      </span>
    </div>
  );
}
