import React, { type ReactNode } from 'react';

import { Ownership } from 'lib/generated_constants/ownership';
import { useAppsignal } from 'hooks/use_appsignal';
import { ErrorBoundary } from '@appsignal/react';

import { OwnershipProvider } from './context';

type OwnerBoundaryProps = {
  children: ReactNode;
} & {
  [key in keyof typeof Ownership]?: boolean;
}

export function OwnerBoundary({ children, ...props }: OwnerBoundaryProps) {
  const ownershipKey = Object.keys(props).find(key => props[key as keyof typeof props]);
  const owner = ownershipKey ? Ownership[ownershipKey as keyof typeof Ownership] : 'frontend';
  const appsignal = useAppsignal({ namespace: owner });

  return (
    <OwnershipProvider appsignal={appsignal} owner={owner}>
      {appsignal ? (
        <ErrorBoundary instance={appsignal}>
          {children}
        </ErrorBoundary>
      ) : (
        children
      )}
    </OwnershipProvider>
  );
}
