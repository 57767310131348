import { CardSizes } from '@user-interviews/ui-design-system';

import * as routes from 'lib/routes';
import { SurveySectionFormValues } from 'participant/project_application/components/survey/types';

import * as Constants from '../constants';

const MAX_SMALL_CARD_GRID_COLUMNS = 3;
const MAX_MEDIUM_CARD_GRID_COLUMNS = 5

export const determineCardSize = (surveySection: {
  questions: {answers: object[], questionGroup?: object}[]
}) => {
  let cardSize: 'sm' | 'md' | 'lg' = CardSizes.SMALL;

  if (surveySection?.questions) {
    const { questions } = surveySection;

    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];

      if (question.questionGroup) {
        const gridAnswerTotal = question.answers.length;

        if (gridAnswerTotal > MAX_MEDIUM_CARD_GRID_COLUMNS) {
          cardSize = CardSizes.LARGE;
          break;
        }

        if (gridAnswerTotal > MAX_SMALL_CARD_GRID_COLUMNS) {
          cardSize = CardSizes.MEDIUM;
        }
      }
    }
  }
  return cardSize;
}

export const redirectToSuccessPage = (responseSet: { accessCode: string }) => {
  const path = routes.project_screener_form_success_path({
    responseSetCode: responseSet.accessCode,
  });

  window.location.assign(path);
};

export const isOtherAnswer = (answer: { responseClass: string }) =>
  answer.responseClass === Constants.RESPONSE_CLASS_OTHER_AND_STRING;

const QUESTION_ID_KEY_REGEX = /q(\d+)/;
export const parseIdFromQuestionIdKey = (attributeIdKey: string) => (
  attributeIdKey.match(QUESTION_ID_KEY_REGEX)?.[1] || null
);

const parsePickOneValues = (formValues: SurveySectionFormValues) => {
  const pickOneFormValues = formValues[Constants.PICK_ONE_INPUT_GROUP_KEY];
  if (!pickOneFormValues) return [];

  return Object.entries(pickOneFormValues)
    .filter(([, { answerId }]) => answerId !== null)
    .map(([questionIdKey, { answerId, otherAnswerId, responseOther }]) => ({
      answerId,
      questionId: parseIdFromQuestionIdKey(questionIdKey),
      ...(answerId === otherAnswerId && { responseOther }),
    }));
};

const parsePickManyValues = (formValues: SurveySectionFormValues) => {
  const pickManyFormValues = formValues[Constants.PICK_MANY_INPUT_GROUP_KEY];
  if (!pickManyFormValues) return [];

  return Object.entries(pickManyFormValues)
    .reduce((
      pickManyResponses: {answerId: string, questionId: string | null, responseOther?: string}[],
      [questionIdKey, { answerIds, otherAnswerId, responseOther }],
    ) => {
      const newResponses = answerIds.map((answerId) => ({
        answerId,
        questionId: parseIdFromQuestionIdKey(questionIdKey),
        ...(answerId === otherAnswerId && { responseOther }),
      }));

      return [...pickManyResponses, ...newResponses];
    }, []);
};

const parseShortAnswerValues = (formValues: SurveySectionFormValues) => {
  const shortAnswerFormValues = formValues[Constants.SHORT_ANSWER_INPUT_GROUP_KEY];
  if (!shortAnswerFormValues) return [];

  return Object.entries(shortAnswerFormValues)
    .filter(([, { value }]) => value !== '')
    .map(([questionIdKey, { answerId, value }]) => ({
      answerId,
      questionId: parseIdFromQuestionIdKey(questionIdKey),
      stringValue: value,
    }));
};

const parseLongAnswerValues = (formValues: SurveySectionFormValues) => {
  const longAnswerFormValues = formValues[Constants.LONG_ANSWER_INPUT_GROUP_KEY];
  if (!longAnswerFormValues) return [];

  return Object.entries(longAnswerFormValues)
    .filter(([, { value }]) => value !== '')
    .map(([questionIdKey, { answerId, value }]) => ({
      answerId,
      questionId: parseIdFromQuestionIdKey(questionIdKey),
      textValue: value,
    }));
};

const parseDateValues = (formValues: SurveySectionFormValues) => {
  const dateFormValues = formValues[Constants.DATE_INPUT_GROUP_KEY];
  if (!dateFormValues) return [];

  return Object.entries(dateFormValues)
    .filter(([, { value }]) => !!value)
    .map(([questionIdKey, { answerId, value }]) => ({
      answerId,
      questionId: parseIdFromQuestionIdKey(questionIdKey),
      dateValue: value,
    }));
};

const parseFloatValues = (formValues: SurveySectionFormValues) => {
  const floatFormValues = formValues[Constants.FLOAT_INPUT_GROUP_KEY];
  if (!floatFormValues) return [];

  return Object.entries(floatFormValues)
    .filter(([, { value }]) => !!value)
    .map(([questionIdKey, { answerId, value }]) => ({
      answerId,
      questionId: parseIdFromQuestionIdKey(questionIdKey),
      floatValue: parseFloat(value),
    }));
};

const parseBooleanValues = (formValues: SurveySectionFormValues) => {
  const booleanFormValues = formValues[Constants.BOOLEAN_INPUT_GROUP_KEY];
  if (!booleanFormValues) return [];

  return Object.entries(booleanFormValues)
    .filter(([, { answerId }]) => answerId !== null)
    .map(([questionIdKey, { answerId, value }]) => ({
      answerId,
      questionId: parseIdFromQuestionIdKey(questionIdKey),
      booleanValue: value === Constants.BOOLEAN_TRUE_VALUE,
    }));
};

export const transformFormValuesToSubmissionParams = (values: SurveySectionFormValues) => ([
  ...parseBooleanValues(values),
  ...parseDateValues(values),
  ...parseFloatValues(values),
  ...parsePickOneValues(values),
  ...parsePickManyValues(values),
  ...parseShortAnswerValues(values),
  ...parseLongAnswerValues(values),
]);

type ProjectMetaParams = {
  participantId: string,
  participantScreenerKey: string | undefined,
  projectId: string,
}

type OptInFormMetaParams = {
  participantId: string,
  participantOptInFormKey: string,
  optInFormId: string,
}

const buildResponseSetCreateParams = ({
  formValues,
  meta,
  surveyId,
}: { formValues: SurveySectionFormValues, surveyId: string, meta: ProjectMetaParams | OptInFormMetaParams}) => ({
  surveyId,
  data: {
    type: 'responseSet',
    attributes: {
      responsesAttributes: transformFormValuesToSubmissionParams(formValues),
    },
  },
  meta,
});

export const buildProjectResponseSetCreateParams = ({
  formValues,
  participantId,
  participantScreenerKey,
  projectId,
  surveyId,
}: { formValues: SurveySectionFormValues, surveyId: string } & ProjectMetaParams) => buildResponseSetCreateParams({
  formValues,
  surveyId,
  meta: {
    participantId,
    participantScreenerKey,
    projectId,
  },
});

export const buildOptInResponseSetCreateParams = ({
  formValues,
  optInFormId,
  participantId,
  participantOptInFormKey,
  surveyId,
}: { formValues: SurveySectionFormValues, surveyId: string } & OptInFormMetaParams) => buildResponseSetCreateParams({
  formValues,
  surveyId,
  meta: {
    optInFormId,
    participantId,
    participantOptInFormKey,
  },
});

const buildResponseSetUpdateParams = ({
  formValues,
  meta,
  responseSetId,
}: { formValues: SurveySectionFormValues, responseSetId: string, meta: ProjectMetaParams | OptInFormMetaParams}) => ({
  data: {
    type: 'responseSet',
    id: responseSetId,
    attributes: {
      responsesAttributes: transformFormValuesToSubmissionParams(formValues),
    },
  },
  meta,
});

export const buildProjectResponseSetUpdateParams = ({
  formValues,
  participantId,
  participantScreenerKey,
  projectId,
  responseSetId,
}: { formValues: SurveySectionFormValues, responseSetId: string } & ProjectMetaParams) => buildResponseSetUpdateParams({
  formValues,
  responseSetId,
  meta: {
    participantId,
    participantScreenerKey,
    projectId,
  },
});

export const buildOptInResponseSetUpdateParams = ({
  formValues,
  optInFormId,
  participantId,
  participantOptInFormKey,
  responseSetId,
}: { formValues: SurveySectionFormValues, responseSetId: string } & OptInFormMetaParams) => buildResponseSetUpdateParams({
  formValues,
  responseSetId,
  meta: {
    optInFormId,
    participantId,
    participantOptInFormKey,
  },
});
