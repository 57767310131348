import React from 'react';

import {
  Card,
  CardSizes,
  Flex,
  Heading,
  Text,
} from '@user-interviews/ui-design-system';
import classNames from 'classnames';

import * as propTypes from 'lib/prop_types';

import StudyDetailsAccordion from 'participant/apply/study_details_accordion';

import styles from './hub_survey_preview_header.module.scss';

export function HubSurveyPreviewHeader({
  cardSize,
  logoUrl,
  projectDetails,
  teamName,
  title,
}) {
  return (
    <>
      <Flex
        alignItems="center"
        className={styles.HubSurveyHeader}
        container
        flexDirection="column"
      >
        {logoUrl && (
          <div
            className={classNames('logo-container logo-container--centered', styles.Logo)}
          >
            <img
              alt={`${teamName} logo`}
              className="logo-thumbnail"
              src={logoUrl}
            />
          </div>
        )}

        <Heading
          level={1}
          size="xxxl"
          textAlign="center"
        >
          Apply to see if you qualify for the study
        </Heading>

        <Text
          size="lg"
          textAlign="center"
        >
          {projectDetails.surveyCompletionTime}
        </Text>
      </Flex>

      <Card noPadding size={cardSize}>
        <StudyDetailsAccordion
          description={projectDetails.description}
          incentive={projectDetails.incentive}
          noIncentive={projectDetails.noIncentive}
          requiresDocumentSignature={projectDetails.requiresDocumentSignature}
          sessionLength={projectDetails.sessionLength}
          title={title}
        />
      </Card>
    </>
  );
}

HubSurveyPreviewHeader.propTypes = {
  cardSize: propTypes.oneOf(Object.values(CardSizes)).isRequired,
  logoUrl: propTypes.string,
  projectDetails: propTypes.object,
  teamName: propTypes.string,
  title: propTypes.string.isRequired,
};

HubSurveyPreviewHeader.defaultProps = {
  logoUrl: undefined,
  projectDetails: undefined,
  teamName: undefined,
};
