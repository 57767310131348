import React from 'react';
import propTypes from 'prop-types';

import { Flex } from '@user-interviews/ui-design-system';

import { surveyAnswerPropType } from '../../prop_types';
import { QuestionTitleCell } from './row_title_cell';

import { AnswerTitleCell } from './column_title_cell';

export function AnswerTitleRow({ answers }) {
  return (
    <Flex
      alignItems="stretch"
      container
    >
      <QuestionTitleCell />
      {answers.map(answer => (
        <AnswerTitleCell
          key={`answer-${answer.id}`}
          title={answer.text}
        />
      ))}
    </Flex>
  );
}

AnswerTitleRow.propTypes = {
  answers: propTypes.arrayOf(surveyAnswerPropType).isRequired,
};
