import { useState } from 'react';

import { MessageTypes } from '@user-interviews/ui-design-system';
import { SetMessageHandler } from '@user-interviews/ui-design-system/lib/Toast/useToast';

import {
  useCreateResponseSetMutation,
  useGetResponseSetsQuery,
  useUpdateResponseSetMutation,
} from 'api/response_sets';

import { ErrorRenderer } from 'lib/errors';

import { SurveySectionFormValues } from 'participant/project_application/components/survey/types';

import { buildOptInResponseSetCreateParams, buildOptInResponseSetUpdateParams } from 'researcher/surveys/utils';

import { responseSetOutdated } from '../utils';

type UseSurveyResponseSetParams = {
  optInFormId: string,
  participantId: string,
  participantOptInFormKey: string,
  surveyId: string,
  setToastMessage: SetMessageHandler;
}

// TODO Placeholder until swagger defs added to response set api endpoints
type SurveySection = {
  id: string,
}

export const useSurveyResponseSet = ({
  optInFormId,
  participantId,
  participantOptInFormKey,
  surveyId,
  setToastMessage,
}: UseSurveyResponseSetParams) => {
  const [currentSurveySection, setCurrentSurveySection] = useState<SurveySection | null>(null);

  const [
    createResponseSet,
    { data: createdResponseSetData, isLoading: createResponseSetLoading },
  ] = useCreateResponseSetMutation();

  const [
    updateResponseSet,
    { data: updatedResponseSetData, isLoading: updateResponseSetLoading },
  ] = useUpdateResponseSetMutation();

  const latestMutatedResponseSetData = updatedResponseSetData || createdResponseSetData;

  let responseSet = latestMutatedResponseSetData?.data;
  const surveyCompletedRedirect = latestMutatedResponseSetData?.meta.surveyCompletedRedirect;

  const isSubmitting = createResponseSetLoading || updateResponseSetLoading;

  const {
    data: existingResponseSetResponse,
    isLoading,
  } = useGetResponseSetsQuery(
    {
      surveyId,
      participantId,
      limit: 1,
      meta: {
        optInFormId,
        participantId,
        participantOptInFormKey,
      },
    },
    { skip: isSubmitting || !!responseSet },
  );

  const existingResponseSet = existingResponseSetResponse?.[0];

  // only use an existing response set if it's not complete and not outdated, so P can retake survey from beginning
  const useExistingResponseSet = existingResponseSet &&
    !existingResponseSet.completedAt &&
    !responseSetOutdated(existingResponseSet);

  if (useExistingResponseSet) {
    responseSet ||= existingResponseSet; // ||= to prioritize latest mutated response set
  }

  const handleError = (error: Error) => {
    setToastMessage({
      type: MessageTypes.ERROR,
      message: ErrorRenderer.compileRtkQueryErrorMessage(error, 'submitting your survey responses'),
    });
  };

  const handleSuccess = (responseSetData: { data: { surveySection: SurveySection } }) => {
    if (responseSetData.data.surveySection) {
      setCurrentSurveySection(responseSetData.data.surveySection);
    }
  };

  const handleUpdate = async (formValues: SurveySectionFormValues) => {
    const params = buildOptInResponseSetUpdateParams({
      formValues,
      optInFormId,
      participantId,
      participantOptInFormKey,
      responseSetId: responseSet.id,
    });

    await updateResponseSet(params)
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  };

  const handleCreate = async (formValues: SurveySectionFormValues) => {
    const params = buildOptInResponseSetCreateParams({
      formValues,
      optInFormId,
      participantId,
      participantOptInFormKey,
      surveyId,
    });

    await createResponseSet(params)
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  };

  const handleSubmit = async (formValues: SurveySectionFormValues) => {
    if (responseSet?.id) return handleUpdate(formValues);

    return handleCreate(formValues);
  };

  return {
    handleSubmit,
    responseSet,
    currentSurveySection: currentSurveySection || (useExistingResponseSet && existingResponseSet?.surveySection),
    isLoading,
    isSubmitting,
    surveyCompletedRedirect,
  };
};
