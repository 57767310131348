import { registerComponent } from 'lib/react_on_rails';

import withAuthUserContext from 'hoc/with_auth_user_context';

import { withPageProviders } from 'hoc/with_page_providers';
import { OptInPage } from './opt_in_page';
import { OptInPreviewPage } from './opt_in_preview_page';

export const OptInEntrypoint = withAuthUserContext(
  OptInPage,
);
export const OptInPreviewEntrypoint = withPageProviders(
  withAuthUserContext(
    OptInPreviewPage,
  ),
);

registerComponent('OptInPage', OptInEntrypoint);
registerComponent('OptInPreviewPage', OptInPreviewEntrypoint)
