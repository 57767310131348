import React from 'react';
import propTypes from 'prop-types';

import { FormProvider, useForm } from 'react-hook-form';

import { useGetSurveyPreviewSection } from '../hooks/use_get_survey_preview_section';
import { useRequestedPreviewSectionParams } from '../hooks/use_requested_preview_section_params';

import { SurveyPreviewForm } from '../components/survey_preview_form';

// Disabled when adding max-props rule.
// Future work should stay under 6 props
// eslint-disable-next-line local-rules/max-props
export function SurveyPreview({
  logoUrl,
  projectDetails,
  publicSurvey,
  surveyId,
  teamName,
  title,
  optInForm,
}) {
  const form = useForm({ shouldUnregister: true });

  const {
    requestedSectionParams,
    updateRequestedSectionParams,
    resetRequestedSectionParams: handleRestartPreview,
  } = useRequestedPreviewSectionParams();

  const {
    isFetching,
    isLoadingFirstSection,
    previewCompleted,
    surveySection,
  } = useGetSurveyPreviewSection(surveyId, requestedSectionParams);

  const handleSubmitSection = form.handleSubmit(
    formValues => updateRequestedSectionParams(formValues, surveySection.id),
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...form}>
      <SurveyPreviewForm
        isLoading={isLoadingFirstSection}
        isSubmitting={isFetching}
        logoUrl={logoUrl}
        optInForm={optInForm}
        previewCompleted={previewCompleted}
        projectDetails={projectDetails}
        publicSurvey={publicSurvey}
        surveySection={surveySection}
        teamName={teamName}
        title={title}
        onRestartPreview={handleRestartPreview}
        onSubmitSection={handleSubmitSection}
      />
    </FormProvider>
  );
}

SurveyPreview.propTypes = {
  logoUrl: propTypes.string,
  optInForm: propTypes.object,
  projectDetails: propTypes.object,
  publicSurvey: propTypes.bool.isRequired,
  surveyId: propTypes.number,
  teamName: propTypes.string,
  title: propTypes.string.isRequired,
};

SurveyPreview.defaultProps = {
  logoUrl: undefined,
  projectDetails: undefined,
  surveyId: undefined,
  teamName: undefined,
  optInForm: null,
};
