import { useLocation } from 'react-router-dom';

export function useOptInFormKeyFromQueryUrl() {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const participantId = searchParams.get('participant_id');
  const participantOptInFormKey = searchParams.get('participant_opt_in_form_key');

  return {
    participantId,
    participantOptInFormKey,
  }
}
