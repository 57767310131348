import React from 'react';
import propTypes from 'prop-types';

import {
  FormControlLabel,
  Input,
  RadioButton,
} from '@user-interviews/ui-design-system';

import { surveyAnswerPropType, surveyQuestionPropType } from '../../prop_types';

// Disabled when adding max-props rule.
// Future work should stay under 6 props
// eslint-disable-next-line local-rules/max-props
export function PickOneAnswerInput({
  answer,
  ariaLabel,
  bordered,
  className,
  formValue,
  hasError,
  isOtherAnswer,
  question,
  text,
  value,
  onBlur,
  onChange,
}) {
  const checked = answer.id === formValue.answerId;

  return (
    <FormControlLabel
      aria-describedby={hasError ? `form-errors-required-${question.id}` : null}
      aria-invalid={hasError ? 'true' : null}
      aria-label={ariaLabel}
      bordered={bordered}
      checked={checked}
      className={className}
      Control={RadioButton}
      id={`pick-one-${answer.id}`}
      name={`answer-${answer.id}`}
      text={text}
      value={value}
      onBlur={onBlur}
      onChange={() => onChange({
        ...formValue,
        answerId: answer.id,
      })}
    >
      {(isOtherAnswer && checked) && (
        <Input
          aria-label="Other input"
          autoComplete="off"
          name={`answer-${answer.id}-other-input`}
          placeholder="Type your response here"
          type="text"
          value={formValue.responseOther}
          onBlur={onBlur}
          onChange={(e) => onChange({ ...formValue, responseOther: e.currentTarget.value })}
        />
      )}
    </FormControlLabel>
  );
}

PickOneAnswerInput.propTypes = {
  answer: surveyAnswerPropType.isRequired,
  ariaLabel: propTypes.string,
  bordered: propTypes.bool,
  className: propTypes.string,
  formValue: propTypes.shape({
    answerId: propTypes.string,
    otherAnswerId: propTypes.string,
    responseOther: propTypes.string,
  }).isRequired,
  hasError: propTypes.bool,
  isOtherAnswer: propTypes.bool,

  question: surveyQuestionPropType.isRequired,
  text: propTypes.string,
  value: propTypes.string.isRequired,
  onBlur: propTypes.func.isRequired,
  onChange: propTypes.func.isRequired,
};

PickOneAnswerInput.defaultProps = {
  ariaLabel: undefined,
  bordered: true,
  className: undefined,
  hasError: undefined,
  isOtherAnswer: false,
  text: '',
};
