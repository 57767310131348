import React from 'react';

import { Card, CardSizes, Text } from '@user-interviews/ui-design-system';

import * as propTypes from 'lib/prop_types';

export function EmptySurveyNotice({ isPreview }) {
  return (
    <Card
      size={CardSizes.SMALL}
    >
      <Text
        size="lg"
        textAlign="center"
        weight="bold"
      >
        The survey for this project is empty
      </Text>

      {isPreview && (
        <Text textAlign="center">
          Please fill in at least one question completely in order to preview the screener survey.
        </Text>
      )}
    </Card>
  );
}

EmptySurveyNotice.propTypes = {
  isPreview: propTypes.bool.isRequired,
};
