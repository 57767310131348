import * as routes from 'lib/routes';

import { apiSlice } from 'api/base_slice';
import { OPT_IN_FORMS_TAG } from 'api/tag_types';
import { deserializeJSONApiResponse, headersAndCredentials } from 'api/utils';

export const optInFormsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getOptInForm: builder.query({
      query: ({ optInFormId, include }) => routes.api_opt_in_form_path(optInFormId, { include }),
      transformResponse: deserializeJSONApiResponse,
      providesTags: (_result, _error, { id }) => [{ type: OPT_IN_FORMS_TAG, id }],
    }),
    updateOptInForm: builder.mutation({
      query: ({ optInFormId, attributes }) => ({
        url: routes.api_opt_in_form_path(optInFormId),
        method: 'PATCH',
        body: {
          data: {
            attributes,
          },
        },
        ...headersAndCredentials(),
      }),
      transformResponse: deserializeJSONApiResponse,
      invalidatesTags: [OPT_IN_FORMS_TAG],
    }),
  }),
  overrideExisting: 'throw',
});

export const {
  useGetOptInFormQuery,
  useUpdateOptInFormMutation,
} = optInFormsApi;
