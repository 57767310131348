import {
  Accordion,
  AccordionCollapse,
  AccordionItem,
  AccordionToggle,
  Button,
  Card,
  Flex,
  Heading,
  Text,
} from '@user-interviews/ui-design-system';
import * as routes from 'lib/routes';
import React from 'react';

import sanitizeHtml from 'sanitize-html';
import styles from './header.module.css'

type HeaderProps = {
  isPreview?: boolean,
  logoOnly: boolean,
  logoUrl: string,
  optInFormDescription?: string,
  optInFormTitle?: string,
  teamName: string,
}

export function Header({
  isPreview = false,
  logoOnly = false,
  logoUrl,
  optInFormDescription,
  optInFormTitle,
  teamName,
}: HeaderProps) {
  const title = optInFormTitle || 'Sign up to participate in user research';

  const description = optInFormDescription || 'Please fill out the information below to provide valuable' +
    ' feedback on our products and services.'

  return (
    <div className={styles.header}>
      {logoUrl && (
        <Flex
          alignItems="center"
          className={styles.logoContainer}
          container
          flexDirection="column"
        >
          <img
            alt={`${teamName} logo`}
            className={styles.logo}
            src={logoUrl}
          />
          {isPreview && (
            <Button href={routes.team_branding_path()} variant="link">
              Edit team logo
            </Button>
          )}
        </Flex>
      )}

      {!logoOnly && (
        <>
          <Heading
            className={styles.title}
            level={1}
            size="xxxl"
            textAlign="center"
          >
            {title}
          </Heading>

          <Card noPadding size="sm">
            <Accordion defaultActiveKey="0" flush>
              <AccordionItem borderless eventKey="0">
                <AccordionToggle
                  eventKey="0"
                  title="Description"
                />
                <AccordionCollapse eventKey="0" UNSAFE_className={styles.descriptionAccordionCollapse}>
                  <Text
                    className={styles.description}
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(description),
                    }}
                    size="md"
                  />
                </AccordionCollapse>
              </AccordionItem>
            </Accordion>
          </Card>
        </>
      )}
    </div>
  )
}
