import React, { useState, useEffect, useMemo } from 'react';
import type { MouseEvent as ReactMouseEvent } from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { Text } from '@user-interviews/ui-design-system';
import { useTracking } from 'react-tracking';

import { useSidebarContext } from '../sidebar/hooks/use_sidebar_context';
import { useIsActive } from './hooks/use_is_active';

import styles from './navigation_item.module.scss';

type LinkProps = {
  href: string,
  target?: string,
}

type ReactRouterLinkProps = {
  to: string
}

type SidebarNavigationItemProps = {
  className?: string,
  event?: string,
  iconSrcBase: string,
  label: string,
  onHandleNavigating?: () => void
}

export function NavigationItem({
  className,
  event,
  iconSrcBase,
  label,
  onHandleNavigating,
  ...linkProps
}: SidebarNavigationItemProps & LinkProps | SidebarNavigationItemProps & ReactRouterLinkProps) {
  const { isRenderingOpen, setIsNavItemHovered } = useSidebarContext();
  const { trackEvent } = useTracking();
  const isActive = useIsActive('href' in linkProps ? linkProps.href : linkProps.to);
  const [isHovered, setIsHovered] = useState(false);

  const SRCs = useMemo(() => ({
    active: `${iconSrcBase}-selected.svg`,
    hovered: `${iconSrcBase}-hover.svg`,
    default: `${iconSrcBase}.svg`,
  }), [iconSrcBase]);

  useEffect(() => {
    Object.values(SRCs).forEach(src => {
      const img = new Image();
      img.src = src;
    });
  }, [SRCs]);

  const iconSrc = isActive ?
    SRCs.active :
    isHovered ?
      SRCs.hovered :
      SRCs.default;

  function handleMouseEnter() {
    setIsHovered(true);
    setIsNavItemHovered(true);
  }

  function handleMouseLeave() {
    setIsHovered(false);
    setIsNavItemHovered(false);
  }

  const baseProps = {
    'aria-label': label,
    className: classNames(
      className,
      styles.navigationItem,
      { [styles.active]: isActive },
    ),
    onMouseEnter: handleMouseEnter,
    onFocus: handleMouseEnter,
    onBlur: handleMouseLeave,
    onMouseLeave: handleMouseLeave,
  };

  function handleClick(ev: ReactMouseEvent<HTMLAnchorElement>) {
    // Check if user uses CMD or Ctrl + Click to open new tab and not trigger loading animation.
    // This prevents having a persistent loading animation on current tab if no page refresh happens.
    const isCmdorCtrlPressed = ev.metaKey || ev.ctrlKey;

    if (onHandleNavigating && !isCmdorCtrlPressed) {
      onHandleNavigating();
    }

    if (!event) return;

    trackEvent({ event });
  }

  if ('href' in linkProps) {
    return (
      <a
        aria-current={isActive ? 'page' : undefined}
        href={linkProps.href}
        target={linkProps.target}
        onClick={(ev) => handleClick(ev)}
        {...baseProps}
      >
        <img alt="" src={iconSrc} />
        {isRenderingOpen && <Text className={styles.label}>{label}</Text>}
      </a>
    );
  }

  return (
    <NavLink
      isActive={() => isActive}
      to={linkProps.to}
      onClick={handleClick}
      {...baseProps}
    >
      <img alt="" src={iconSrc} />
      {isRenderingOpen && <Text className={styles.label}>{label}</Text>}
    </NavLink>
  );
}
